import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import './GanadoresData.css';
import baseURL from '../../url';
import NewGanador from '../NewGanador/NewGanador';

export default function GanadoresData() {
    const [ganadores, setGanadores] = useState([]);

    useEffect(() => {
        cargarGanadores();
    }, []);

    const cargarGanadores = () => {
        fetch(`${baseURL}/ganadorGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setGanadores(data.ganadores || []);
                console.log(data.ganadores);
            })
            .catch(error => console.error('Error al cargar ganadores:', error));
    };

    const eliminarGanador = (idGanador) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${baseURL}/ganadorDelete.php?idGanador=${idGanador}`, {
                    method: 'DELETE',
                })
                    .then(response => response.json())
                    .then(data => {
                        Swal.fire('¡Eliminado!', data.mensaje, 'success');
                        cargarGanadores();
                    })
                    .catch(error => {
                        console.error('Error al eliminar ganador:', error);
                        toast.error(error);
                    });
            }
        });
    };

    return (
        <div>
            <ToastContainer />
            <NewGanador />

            <div className='table-container'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Id Ganador</th>
                            <th>Rifa</th>
                            <th>Número</th>
                            <th>Nombre</th>
                            <th>Telefono</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ganadores.map(item => (
                            <tr key={item.idGanador}>
                                <td>{item.idGanador}</td>
                                <td>{item.nombreRifa}</td>
                                <td>{item.numero}</td>
                                <td>{item.nombreGanador}</td>
                                <td>{item.telefono}</td>
                                <td>
                                    <button className='eliminar' onClick={() => eliminarGanador(item.idGanador)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}
