import React, { useState, useEffect } from 'react';
import './NewRifa.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import baseURL from '../../url';

export default function NewRifa() {
    const [mensaje, setMensaje] = useState('');
    const [imagenPreview1, setImagenPreview1] = useState(null);
    const [nombre, setNombre] = useState('');
    const [numeros, setNumeros] = useState('');
    const [finalizacion, setFinalizacion] = useState('');
    const [isImage1Selected, setIsImage1Selected] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [moneda, setMoneda] = useState('');
    const [precio, setPrecio] = useState('');
    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const handleImagenChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const previewURL = URL.createObjectURL(file);
            setImagenPreview1(previewURL);
            setIsImage1Selected(true);
        }
    };
    const handleMoneda = (e) => {
        setMoneda(e.target.value);
    };

    const crear = async () => {
        const formData = new FormData();
        formData.append('imagen1', document.getElementById("imagen1").files[0]);
        formData.append('nombre', nombre);
        formData.append('numeros', numeros);
        formData.append('finalizacion', finalizacion);
        formData.append('moneda', moneda);
        formData.append('precio', precio);

        setMensaje('');

        if (!nombre || !numeros || !finalizacion || !isImage1Selected) {
            toast.error('Por favor, complete todos los campos correctamente.');
            return;
        }

        setMensaje('Procesando...');

        try {
            const response = await fetch(`${baseURL}/rifaPost.php`, {
                method: 'POST',
                body: formData
            });

            const data = await response.json();

            if (data.mensaje) {
                setMensaje('');
                toast.success(data.mensaje);
                window.location.reload();
            } else if (data.error) {
                setMensaje('');
                toast.error(data.error);
            }
        } catch (error) {
            console.error('Error:', error);
            setMensaje('');
            toast.error('Error de conexión. Por favor, inténtelo de nuevo.');
        }
    };

    return (
        <div className='NewContain'>
            <ToastContainer />
            <button onClick={toggleModal} className='btnSave'>
                <span> +</span> Agregar
            </button>
            {modalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <div className='deFlexBtnsModal'>
                            <button className='selected'>Agregar Rifa</button>
                            <span className="close" onClick={toggleModal}>&times;</span>
                        </div>
                        <form id="crearForm">
                            <div className='previevImagesLogoBanner'>
                                {isImage1Selected && <img src={imagenPreview1} alt="Vista previa" className='previevImages' />}
                            </div>
                            <div className='flexGrap'>
                                <fieldset>
                                    <legend>Imagen  (obligatorio)</legend>
                                    <input
                                        type="file"
                                        id="imagen1"
                                        accept="image/*"
                                        onChange={handleImagenChange}
                                        required
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Nombre  (obligatorio)</legend>
                                    <input
                                        type="text"
                                        required
                                        value={nombre}
                                        onChange={(e) => setNombre(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Números  (obligatorio)</legend>
                                    <input
                                        type="number"
                                        required
                                        value={numeros}
                                        onChange={(e) => setNumeros(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Fecha de Finalización  (obligatorio)</legend>
                                    <input
                                        type="datetime-local"
                                        required
                                        value={finalizacion}
                                        onChange={(e) => setFinalizacion(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Moneda (obligatorio)</legend>
                                    <select
                                        id="moneda"
                                        name="moneda"
                                        value={moneda}
                                        onChange={handleMoneda}
                                    >
                                        <option value="">Selecciona una moneda</option>
                                        <option value="$">$</option>
                                        <option value="S/">S/</option>
                                        <option value="€">€</option>
                                        <option value="£">£</option>
                                        <option value="¥">¥</option>
                                        <option value="₹">₹</option>
                                        <option value="₩">₩</option>
                                        <option value="R$">R$</option>
                                        <option value="₽">₽</option>
                                        <option value="฿">฿</option>
                                        <option value="C$">C$</option>
                                        <option value="A$">A$</option>
                                        <option value="₫">₫</option>
                                        <option value="CHF">CHF</option>
                                        <option value="kr">kr</option>
                                        <option value="Bs">Bs</option>
                                    </select>
                                </fieldset>
                                <fieldset>
                                    <legend>Precio  (obligatorio)</legend>
                                    <input
                                        type="number"
                                        required
                                        value={precio}
                                        onChange={(e) => setPrecio(e.target.value)}
                                    />
                                </fieldset>
                            </div>
                            {mensaje ? (
                                <button type="button" className='btnLoading' disabled>
                                    {mensaje}
                                </button>
                            ) : (
                                <button type="button" onClick={crear} className='btnPost'>
                                    Agregar
                                </button>
                            )}
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}
