import React, { useEffect, useState } from 'react';
import './CardsCantidad.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faUsers, faImage, faBook, faStar, faCode } from '@fortawesome/free-solid-svg-icons';
import { Link as Anchor } from "react-router-dom";
import baseURL from '../../url';
export default function CardsCantidad() {
    const [rifas, setRifas] = useState([]);
    const [usuarios, setUsuarios] = useState([]);
    const [numeros, setNumeros] = useState([]);
    const [ganadores, setGanadores] = useState([]);



    useEffect(() => {
        cargarRifas();
        cargarUsuarios();
        cargarNumeros();
        cargarGanadores();
    }, []);

    const cargarRifas = () => {
        fetch(`${baseURL}/rifaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setRifas(data.rifas || []);
            })
            .catch(error => console.error('Error al cargar rifas:', error));
    };


    const cargarUsuarios = () => {
        fetch(`${baseURL}/usuariosGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setUsuarios(data.usuarios || []);
            })
            .catch(error => console.error('Error al cargar usuarios:', error));
    };

    const cargarNumeros = () => {
        fetch(`${baseURL}/numeroGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setNumeros(data.numeros || []);
            })
            .catch(error => console.error('Error al cargar numeros:', error));
    };
    const cargarGanadores = () => {
        fetch(`${baseURL}/ganadorGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setGanadores(data.ganadores || []);
                console.log(data.ganadores);
            })
            .catch(error => console.error('Error al cargar ganadores:', error));
    };




    return (
        <div className='CardsCantidad'>

            <Anchor to={`/dashboard/usuarios`} className='cardCantidad'>
                <FontAwesomeIcon icon={faUsers} className='icons' />
                <div className='deColumn'>

                    <h3>Usuarios</h3>
                    <h2>{usuarios.length}</h2>
                </div>

            </Anchor>
            <Anchor to={`/dashboard/rifas`} className='cardCantidad' >
                <FontAwesomeIcon icon={faStar} className='icons' />
                <div className='deColumn'>

                    <h3>Rifas</h3>
                    <h2>{rifas.length}</h2>
                </div>

            </Anchor>
            <Anchor to={`/dashboard/numeros`} className='cardCantidad' >
                <FontAwesomeIcon icon={faBook} className='icons' />
                <div className='deColumn'>

                    <h3>Numeros</h3>
                    <h2>{numeros.length}</h2>
                </div>

            </Anchor>

            <Anchor to={`/dashboard/ganadores`} className='cardCantidad' >
                <FontAwesomeIcon icon={faUser} className='icons' />
                <div className='deColumn'>

                    <h3>Ganadores</h3>
                    <h2>{ganadores.length}</h2>
                </div>

            </Anchor>

        </div>
    )
}
