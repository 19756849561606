import IndexLayout from "../Layouts/IndexLayout";
import MainLayout from "../Layouts/MainLayout";
import PageLayout from "../Layouts/PageLayout";
import { createBrowserRouter } from "react-router-dom";
import Usuarios from '../Pages/Usuarios/Usuarios'
import Main from "./Main/Main";
import Rifas from '../Pages/Rifas/Rifas'
import Numeros from '../Pages/Numeros/Numeros'
import Banners from "./Banners/Banners";
import Contacto from "./Contacto/Contacto";
import Ganadores from "./Ganadores/Ganadores";
import PageDetail from '../Pages/PageDetail/PageDetail';
export const router = createBrowserRouter([

    {
        path: "/",
        element: <IndexLayout />,
        children: [
            {
                path: `/`,
                element: <Main />,
            },


        ],
    },

    {
        path: "/",
        element: <MainLayout />,
        children: [
            {
                path: `/dashboard`,
                element: <Main />,
            },
            {
                path: `/dashboard/usuarios`,
                element: <Usuarios />,
            },
            {
                path: `/dashboard/rifas`,
                element: <Rifas />,
            },
            {
                path: `/dashboard/numeros`,
                element: <Numeros />,
            },
            {
                path: `/dashboard/banners`,
                element: <Banners />,
            },
            {
                path: `/dashboard/contacto`,
                element: <Contacto />,
            },
            {
                path: `/dashboard/ganadores`,
                element: <Ganadores />,
            },

        ],
    },
    {
        path: "/",
        element: <PageLayout />,
        children: [
            {
                path: `/rifa/:idRifa/:nombre`,
                element: <PageDetail />,
            },


        ],
    },

]);
