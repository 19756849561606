import React, { useEffect, useState } from 'react';
import './RifasMain.css'
import { Link as Anchor } from "react-router-dom";
import baseURL from '../../url';
export default function RifasMain() {
    const [rifas, setRifas] = useState([]);


    useEffect(() => {
        cargarRifas();
    }, []);

    const cargarRifas = () => {
        fetch(`${baseURL}/rifaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setRifas(data.rifas?.reverse()?.slice(0, 5) || []);
            })
            .catch(error => console.error('Error al cargar rifas:', error));
    };



    return (



        <div className='table-containerUsuarios'>
            <div className='deFlexMore'>
                <h3>Ultimos {rifas?.length} registros</h3>
                <Anchor to={`/dashboard/rifas`} className='logo'>
                    Ver más
                </Anchor>
            </div>

            <table className='table'>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Nombre</th>
                        <th>Estado</th>
                        <th>Finalizacion</th>
                        <th>Creacion</th>

                    </tr>
                </thead>
                <tbody>
                    {rifas.map(item => (
                        <tr key={item.idRifa}>
                            <td>{item.idRifa}</td>
                            <td>{item.nombre}</td>
                            <td style={{
                                color: item?.estado === 'disponible' ? '#008000' : item?.estado === 'finalizada' ? '#FF0000' : '#000000',
                            }}>
                                {`${item?.estado}`}
                            </td>
                            <td>{item.finalizacion}</td>
                            <td>{item.createdAt}</td>
                        </tr>
                    ))}
                </tbody>

            </table>
        </div>

    );
};
