import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import './Detail.css';
import Modal from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faExternalLinkAlt, faStar, faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import whatsappIcon from '../../images/wpp.png';
import { useNavigate, useLocation } from "react-router-dom";
import baseURL from '../url';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NumerosRifas from "../NumerosRifas/NumerosRifas";
import DetailLoading from "../DetailLoading/DetailLoading";
import Search from "../Search/Search";
import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper/core';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Navigation, Pagination, Autoplay]);
export default function Detail() {
    const navigate = useNavigate();
    const { idRifa } = useParams();
    const [rifa, setRifa] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalImage, setModalImage] = useState("");
    const [rifas, setRifas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [contactos, setContactos] = useState([]);
    const location = useLocation();
    const [ganadores, setGanadores] = useState([]);
    const [ganador, setGanador] = useState([]);
    const swiperRef = useRef(null);
    useEffect(() => {
        cargarRifas();
        cargarContacto();
        cargarGanadores();
    }, []);

    const cargarContacto = () => {
        fetch(`${baseURL}/contactoGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setContactos(data.contacto.reverse()[0] || []);
            })
            .catch(error => console.error('Error al cargar contactos:', error));
    };

    const cargarRifas = () => {
        fetch(`${baseURL}/rifaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setRifas(data.rifas || []);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error al cargar rifas:', error);
                setLoading(true);
            });
    };

    const cargarGanadores = () => {
        fetch(`${baseURL}/ganadorGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setGanadores(data.ganadores || []);
            })
            .catch(error => console.error('Error al cargar ganadores:', error));
    };

    useEffect(() => {
        const rif = rifas.find((e) => e.idRifa === parseInt(idRifa));
        setRifa(rif);

        // Filtrar los ganadores para obtener solo aquellos que pertenecen a la rifa actual
        const ganados = ganadores.filter((e) => e.idRifa === parseInt(idRifa));
        setGanador(ganados);
    }, [idRifa, rifas, ganadores]);

    const handleCompartirClick = () => {
        if (navigator.share) {
            navigator.share({
                title: document.title,
                text: 'Echa un vistazo a este producto',
                url: window.location.href,
            })
                .then(() => console.log('Contenido compartido correctamente'))
                .catch((error) => console.error('Error al compartir:', error));
        } else {
            console.error('La API de compartir no está disponible en este navegador.');
        }
    };

    const handleWhatsappMessage = () => {
        const phoneNumber = contactos?.telefono;
        const title = encodeURIComponent(rifa?.nombre?.replace(/\s+/g, '-'));

        const message = `Hola 🌟, quisiera más información sobre\n\n✅ *${title}*\n   `;

        const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

        window.open(whatsappUrl, '_blank');
    };

    const goBack = () => {
        if (location.key !== 'default') {
            navigate(-1);
        } else {
            navigate('/');
        }
    };

    if (!rifa) {
        return <DetailLoading />;
    }

    return (
        <div className="detail" >
            <ToastContainer />
            <div className="deFlexDetail">
                <button className="back" onClick={goBack}> <FontAwesomeIcon icon={faArrowLeft} /> </button>
                <button className="share" onClick={handleCompartirClick}> <FontAwesomeIcon icon={faExternalLinkAlt} /> </button>
            </div>
            <div className="detail-contain">
                <div className="detailText" >
                    <div className="imgContain" onClick={() => {
                        setModalImage(rifa.imagen1);
                        setIsModalOpen(true);
                    }}>
                        {rifa.imagen1 && (
                            <img
                                src={rifa.imagen1}
                                alt={rifa.titulo}
                                className="imagen1"
                                onClick={() => {
                                    setModalImage(rifa.imagen1);
                                    setIsModalOpen(true);
                                }}
                            />
                        )}
                    </div>
                    <div className="textDetail">
                        <h2 className="title"><FontAwesomeIcon icon={faStar} />  {rifa.nombre}  </h2>
                        <h4>{rifa?.moneda} {rifa?.precio}</h4>

                        {rifa?.estado === 'finalizada' ? (
                            <h3>Rifa Finalizada</h3>
                        ) : (<h3> <strong>Finaliza:</strong> {rifa.finalizacion}</h3>)}

                        <div className='deFlexGoTobt'>
                            <button className="wpp" onClick={handleWhatsappMessage}>
                                Consultar
                                <img src={whatsappIcon} alt="whatsappIcon" />
                            </button>
                            <Search />

                        </div>
                    </div>

                </div>
                {ganador?.length > 0 && (
                    <div className="margin">
                        <div className='titleSection'>
                            <h2>Ganadores</h2>
                            <FontAwesomeIcon icon={faAngleDoubleRight} className='iconTitle' />
                        </div>
                    </div>
                )}
                {ganador?.length > 0 && (
                    <Swiper
                        effect={'coverflow'}
                        grabCursor={true}
                        slidesPerView={'auto'}
                        className="containGana"
                    >
                        {ganador.map((item, index) => (
                            <SwiperSlide key={item.idGanador} className="ganadorText">
                                <span className="puesto">{index + 1} lugar: </span>
                                <strong>
                                    {item.numero} - {item.nombreGanador === '' ? 'No hubo ganador' : item.nombreGanador}
                                </strong>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}





                <NumerosRifas />

            </div>



            <Modal
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                center
                classNames={{
                    modal: 'custom-modal',
                }}
            >
                <img src={modalImage} alt={rifa.titulo} />
            </Modal>

        </div>
    );
}
