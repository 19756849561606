import React, { useState, useEffect } from 'react';
import baseURL from '../../url';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function NewGanador() {
    const [mensaje, setMensaje] = useState('');
    const [idRifa, setIdRifa] = useState('');
    const [rifas, setRifas] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [numero, setNumero] = useState(''); // Cambiar de idNumero a numero

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    useEffect(() => {
        cargarRifas();
    }, []);

    const cargarRifas = () => {
        fetch(`${baseURL}/rifaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setRifas(data.rifas || []);
            })
            .catch(error => console.error('Error al cargar rifas:', error));
    };

    const handleRifa = (e) => {
        setIdRifa(e.target.value);
    };

    const handleNumero = (e) => {
        setNumero(e.target.value); // Cambiar a número
    };

    const crear = async () => {
        const formData = new FormData();
        formData.append('idRifa', idRifa);

        // Si el número tiene un valor, agregarlo al formData
        if (numero) {
            formData.append('numero', numero); // Cambiar de idNumero a numero
        }

        setMensaje('Procesando...');

        try {
            const response = await fetch(`${baseURL}/ganadorPost.php`, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();

            if (data.mensaje) {
                setMensaje('');
                toast.success(data.mensaje);
                toggleModal();
                window.location.reload();
            } else if (data.error) {
                setMensaje('');
                toast.error(data.error);
            }
        } catch (error) {
            console.error('Error:', error);
            setMensaje('');
            toast.error('Error de conexión. Por favor, inténtelo de nuevo.');
        }
    };

    return (
        <div className='NewContain'>
            <ToastContainer />
            <button onClick={toggleModal} className='btnSave'>
                <span>+</span> Agregar
            </button>
            {modalOpen && (
                <div className='modal'>
                    <div className='modal-content'>
                        <div className='deFlexBtnsModal'>
                            <button className='selected'>Generar Ganador</button>
                            <span className="close" onClick={toggleModal}>&times;</span>
                        </div>
                        <form className='flexGrap'>
                            <fieldset>
                                <legend>Rifa (obligatorio)</legend>
                                <select
                                    id="idRifa"
                                    name="idRifa"
                                    value={idRifa}
                                    onChange={handleRifa}
                                >
                                    <option value="">Selecciona una rifa</option>
                                    {rifas.map(item => (
                                        <option key={item.idRifa} value={item.idRifa}>{item.nombre}</option>
                                    ))}
                                </select>
                            </fieldset>
                            <fieldset>
                                <legend>Número (opcional)</legend>
                                <input
                                    type="number"
                                    id="numero" // Cambiar el id a 'numero'
                                    name="numero" // Cambiar el name a 'numero'
                                    value={numero}
                                    onChange={handleNumero}
                                    min="1"
                                />
                            </fieldset>
                            {mensaje ? (
                                <button type='button' className='btnLoading' disabled>
                                    {mensaje}
                                </button>
                            ) : (
                                <button type='button' onClick={crear} className='btnPost'>
                                    Agregar
                                </button>
                            )}
                        </form>
                    </div>
                </div>
            )}
        </div>
    );
}
