import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit, faArrowUp, faArrowDown, faSync, faEye } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import baseURL from '../../url';
import NewRifa from '../NewRifa/NewRifa';
import './RifasData.css'
import { Link as Anchor } from "react-router-dom";
export default function RifasData() {
    const [rifas, setRifas] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [nuevoNombre, setNuevoNombre] = useState('');
    const [finalizacion, setFinalizacion] = useState('');
    const [rifa, setRifa] = useState({});
    const [modalImagenVisible, setModalImagenVisible] = useState(false);
    const [imagenSeleccionada, setImagenSeleccionada] = useState('');
    const [filtroId, setFiltroId] = useState('');
    const [filtroEstado, setFiltroEstado] = useState('');
    const [filtroNombre, setFiltroNombre] = useState('');
    const [ordenInvertido, setOrdenInvertido] = useState(false);
    const [imagenPreview, setImagenPreview] = useState(null);
    const [nuevaImagen, setNuevaImagen] = useState(null);
    const [selectedSection, setSelectedSection] = useState('texto');
    const [nuevoEstado, setNuevoEstado] = useState('');
    const [moneda, setMoneda] = useState('');
    const [precio, setPrecio] = useState('');

    const cerrarModalImagen = () => {
        setModalImagenVisible(false);
    };
    const abrirModalImagenSeleccionada = (imagen) => {
        setImagenSeleccionada(imagen);
        setModalImagenVisible(true);
    };


    useEffect(() => {
        cargarRifas();

    }, []);


    const cargarRifas = () => {
        fetch(`${baseURL}/rifaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setRifas(data.rifas || []);
                console.log(data.rifas);
            })
            .catch(error => console.error('Error al cargar datos:', error));
    };


    const eliminar = (idRifa) => {
        // Reemplaza el window.confirm con SweetAlert2
        Swal.fire({
            title: '¿Estás seguro?',
            text: '¡No podrás revertir esto!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(`${baseURL}/rifaDelete.php?idRifa=${idRifa}`, {
                    method: 'DELETE',
                })
                    .then(response => response.json())
                    .then(data => {
                        Swal.fire(
                            '¡Eliminado!',
                            data.mensaje,
                            'success'
                        );
                        cargarRifas();
                    })
                    .catch(error => {
                        console.error('Error al eliminar la Producto:', error);
                        toast.error(error);
                    });
            }
        });
    };

    const abrirModal = (item) => {
        setRifa(item);
        setNuevoNombre(item.nombre);
        setFinalizacion(item.finalizacion);
        setNuevoEstado(item.estado);
        setMoneda(item.moneda);
        setPrecio(item.precio);
        setModalVisible(true);
    };

    const cerrarModal = () => {
        setModalVisible(false);
    };

    const filtrados = rifas.filter(item => {
        const idMatch = item.idRifa.toString().includes(filtroId);
        const NombreMatch = !filtroNombre || item.nombre.toLowerCase().indexOf(filtroNombre.toLowerCase()) !== -1;
        const estadoMatch = !filtroEstado || item.estado === filtroEstado;
        return idMatch && NombreMatch && estadoMatch;
    });

    const descargarExcel = () => {
        const data = filtrados.map(item => ({
            idRifa: item.idRifa,
            Nombre: item.nombre,
            Numeros: item.numeros,
            Estado: item.estado,
            Finalizacion: item.finalizacion,
            Creacion: item.createdAt,


        }));

        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Rifas');
        XLSX.writeFile(wb, 'rifas.xlsx');
    };

    const descargarPDF = () => {
        const pdf = new jsPDF();
        pdf.text('Lista de rifas', 10, 10);

        const columns = [
            { title: 'idRifa', dataKey: 'idRifa' },
            { title: 'Nombre', dataKey: 'nombre' },
            { title: 'Numeros', dataKey: 'numeros' },
            { title: 'Estado', dataKey: 'estado' },
            { title: 'Finalizacion', dataKey: 'finalizacion' },
            { title: 'Creacion', dataKey: 'createdAt' },
        ];

        const data = filtrados.map(item => ({
            idRifa: item.idRifa,
            Nombre: item.nombre,
            Numeros: item.numeros,
            Estado: item.estado,
            Finalizacion: item.finalizacion,
            Creacion: item.createdAt,

        }));

        pdf.autoTable({
            head: [columns.map(col => col.title)],
            body: data.map(item => Object.values(item)),
        });

        pdf.save('rifas.pdf');
    };

    const recargar = () => {
        cargarRifas();
    };
    const invertirOrden = () => {
        setRifas([...rifas].reverse());
        setOrdenInvertido(!ordenInvertido);
    };


    const handleUpdateText = (idRifa) => {
        const payload = {
            nuevoNombre: nuevoNombre !== '' ? nuevoNombre : rifa.nombre,
            nuevaFinalizacion: finalizacion !== '' ? finalizacion : rifa.finalizacion,
            estado: nuevoEstado !== '' ? nuevoEstado : rifa.estado,
            moneda: moneda !== '' ? moneda : rifa.moneda,
            precio: precio !== '' ? precio : rifa.precio,
        };

        fetch(`${baseURL}/rifaTextPut.php?idRifa=${idRifa}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        })
            .then(response => response.json())
            .then(data => {
                if (data.error) {

                    Swal.fire(
                        'Error!',
                        data.error,
                        'error'
                    );
                } else {

                    Swal.fire(
                        'Editado!',
                        data.mensaje,
                        'success'
                    );
                    recargar();
                    cerrarModal()
                }
            })
            .catch(error => {
                console.log(error.message);
                toast.error(error.message);
            });
    };

    const handleFileChange = (event, setFile, setPreview) => {
        const file = event.target.files[0];

        if (file) {
            // Crear una URL de objeto para la imagen seleccionada
            const previewURL = URL.createObjectURL(file);
            setFile(file);
            setPreview(previewURL);
        }
    };
    const handleEditarImagenBanner = (idRifa) => {
        const formData = new FormData();
        formData.append('idRifa', idRifa);
        formData.append('updateAction', 'update'); // Campo adicional para indicar que es una actualización

        if (nuevaImagen) {
            formData.append('imagen1', nuevaImagen);
        }


        fetch(`${baseURL}/rifaImagePut.php`, {
            method: 'POST',  // Cambiado a POST
            body: formData
        })
            .then(response => {
                // Manejar el caso cuando la respuesta no es un JSON válido o está vacía
                if (!response.ok) {
                    throw new Error('La solicitud no fue exitosa');

                }

                return response.json();
            })
            .then(data => {
                if (data.error) {

                    toast.error(data.error);
                    console.log(formData)
                } else {

                    toast.success(data.mensaje);
                    window.location.reload();
                }
            })
            .catch(error => {
                console.log(error)
                toast.error(error.message);
                console.log(formData)
                console.log(idRifa)
            });
    };

    const handleSectionChange = (section) => {
        setSelectedSection(section);
    };


    return (
        <div>

            <ToastContainer />
            <div className='deFlexContent'>

                <div className='deFlex2'>
                    <NewRifa />
                    <button className='excel' onClick={descargarExcel}><FontAwesomeIcon icon={faArrowDown} /> Excel</button>
                    <button className='pdf' onClick={descargarPDF}><FontAwesomeIcon icon={faArrowDown} /> PDF</button>
                </div>
                <div className='filtrosContain'>
                    <div className='inputsColumn'>
                        <button className='' onClick={recargar}>{String(filtrados?.length)?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")} / {String(rifas?.length)?.replace(/\B(?=(\d{3})+(?!\d))/g, ".")} </button>
                    </div>
                    <div className='inputsColumn'>
                        <input type="number" value={filtroId} onChange={(e) => setFiltroId(e.target.value)} placeholder='Id rifa' />
                    </div>
                    <div className='inputsColumn'>
                        <input type="text" value={filtroNombre} onChange={(e) => setFiltroNombre(e.target.value)} placeholder='Nombre' />
                    </div>

                    <div className='inputsColumn'>
                        <select value={filtroEstado} onChange={(e) => setFiltroEstado(e.target.value)}>
                            <option value="">Estado</option>
                            <option value='disponible'>Disponible</option>
                            <option value='finalizada'>Finalizada</option>
                        </select>
                    </div>
                    <button className='reload' onClick={recargar}><FontAwesomeIcon icon={faSync} /></button>
                    <button className='reverse' onClick={invertirOrden}>
                        {ordenInvertido ? <FontAwesomeIcon icon={faArrowUp} /> : <FontAwesomeIcon icon={faArrowDown} />}
                    </button>

                </div>

            </div>


            {modalImagenVisible && (
                <div className="modalImg">
                    <div className="modal-contentImg">


                        <span className="close2" onClick={cerrarModalImagen}>
                            &times;
                        </span>

                        <img src={imagenSeleccionada} alt="Imagen Seleccionada" />
                    </div>
                </div>
            )}

            {modalVisible && (
                <div className="modal">
                    <div className="modal-content">
                        <div className='deFlexBtnsModal'>

                            <div className='deFlexBtnsModal'>
                                <button
                                    className={selectedSection === 'texto' ? 'selected' : ''}
                                    onClick={() => handleSectionChange('texto')}
                                >
                                    Editar Texto
                                </button>
                                <button
                                    className={selectedSection === 'imagenes' ? 'selected' : ''}
                                    onClick={() => handleSectionChange('imagenes')}
                                >
                                    Editar Imagenes
                                </button>
                            </div>
                            <span className="close" onClick={cerrarModal}>
                                &times;
                            </span>
                        </div>
                        <div className='sectiontext' style={{ display: selectedSection === 'texto' ? 'flex' : 'none' }}>
                            <div className='flexGrap'>
                                <fieldset>
                                    <legend>Nombre</legend>
                                    <input
                                        type="text"
                                        value={nuevoNombre}
                                        onChange={(e) => setNuevoNombre(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Estado</legend>
                                    <select
                                        value={nuevoEstado !== '' ? nuevoEstado : rifa.estado}
                                        onChange={(e) => setNuevoEstado(e.target.value)}
                                    >
                                        <option value={rifa?.estado}>{rifa?.estado}</option>
                                        <option value='disponible'>Disponible</option>
                                        <option value='finalizada'>Finalizada</option>
                                    </select>
                                </fieldset>
                                <fieldset>
                                    <legend>Fecha de Finalización</legend>
                                    <input
                                        type="datetime-local"
                                        required
                                        value={finalizacion}
                                        onChange={(e) => setFinalizacion(e.target.value)}
                                    />
                                </fieldset>
                                <fieldset>
                                    <legend>Moneda</legend>
                                    <select
                                        value={moneda}
                                        onChange={(e) => setMoneda(e.target.value)}
                                    >
                                        <option value="">Selecciona una moneda</option>
                                        <option value="$">$</option>
                                        <option value="S/">S/</option>
                                        <option value="€">€</option>
                                        <option value="£">£</option>
                                        <option value="¥">¥</option>
                                        <option value="₹">₹</option>
                                        <option value="₩">₩</option>
                                        <option value="R$">R$</option>
                                        <option value="₽">₽</option>
                                        <option value="฿">฿</option>
                                        <option value="C$">C$</option>
                                        <option value="A$">A$</option>
                                        <option value="₫">₫</option>
                                        <option value="CHF">CHF</option>
                                        <option value="kr">kr</option>
                                        <option value="Bs">Bs</option>
                                    </select>
                                </fieldset>
                                <fieldset>
                                    <legend>Precio</legend>
                                    <input
                                        type="number"
                                        value={precio}
                                        onChange={(e) => setPrecio(e.target.value)}
                                    />
                                </fieldset>
                            </div>
                            <button className='btnPost' onClick={() => handleUpdateText(rifa.idRifa)} >Guardar </button>
                        </div>



                        <div className='sectionImg' style={{ display: selectedSection === 'imagenes' ? 'flex' : 'none' }}>
                            <div CLAS='previevImagesLogoBanner'>


                                {imagenPreview ? (
                                    <img src={imagenPreview} alt="Vista previa de la imagen" onClick={() => abrirModalImagenSeleccionada(rifa.imagen1)} className='previevImages' />
                                ) : (
                                    <>
                                        {rifa.imagen1 ? (
                                            <img src={rifa.imagen1} alt="imagen" onClick={() => abrirModalImagenSeleccionada(rifa.imagen1)} className='previevImages' />

                                        ) : (
                                            <span className='imgNone'>
                                                No hay imagen

                                            </span>
                                        )}
                                    </>
                                )}


                            </div>

                            <div className='flexGrap'>
                                <fieldset>
                                    <legend>Editar Imagen  </legend>
                                    <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, setNuevaImagen, setImagenPreview)} />
                                </fieldset>

                            </div>
                            <button className='btnPost' onClick={() => handleEditarImagenBanner(rifa.idRifa)}>Guardar </button>
                        </div>
                    </div>







                </div>
            )}
            <div className='table-container'>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>Id Rifa</th>
                            <th>Estado</th>
                            <th>Nombre</th>
                            <th>Numeros</th>
                            <th>Precio</th>
                            <th>Finalizacion</th>
                            <th>Imagen</th>
                            <th>Creacion</th>
                            <th>Acciones</th>
                        </tr>
                    </thead>

                    <tbody>
                        {filtrados?.map(item => (
                            <tr key={item.idRifa}>
                                <td>{item.idRifa}</td>
                                <td style={{
                                    color: item?.estado === 'disponible' ? '#008000' : item?.estado === 'finalizada' ? '#FF0000' : '#000000',
                                }}>
                                    {`${item?.estado}`}
                                </td>
                                <td>{item.nombre}</td>
                                <td>{item.numeros}</td>
                                <td>{item.moneda} {item.precio}</td>
                                <td>{item.finalizacion}</td>
                                <td>
                                    {item.imagen1 ? (
                                        <img src={item.imagen1} alt="imagen1" />
                                    ) : (
                                        <span className='imgNonetd'>
                                            Sin imagen
                                        </span>
                                    )}
                                </td>
                                <td>{item.createdAt}</td>
                                <td>
                                    <button className='eliminar' onClick={() => eliminar(item.idRifa)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </button>
                                    <button className='editar' onClick={() => abrirModal(item)}>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </button>
                                    <Anchor className='editar' to={`/rifa/${item?.idRifa}/${item?.nombre?.replace(/\s+/g, '-')}`}>
                                        <FontAwesomeIcon icon={faEye} />
                                    </Anchor>
                                </td>
                            </tr>
                        ))}
                    </tbody>

                </table>
            </div>
        </div>
    );
};
