import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import './Terminos.css';
import baseURL from '../../url';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
export default function Terminos() {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        const isModalClosed = sessionStorage.getItem('modalClosed');
        if (!isModalClosed) {
            setModalIsOpen(true);
        }
    }, []);

    const handleCloseModal = () => {
        sessionStorage.setItem('modalClosed', 'true');
        setModalIsOpen(false);
    };

    const handleOpenModal = () => {
        setModalIsOpen(true);
    };


    return (
        <div className='Terminos'>
            <strong onClick={handleOpenModal}>
                <FontAwesomeIcon icon={faBook} /> Terminos
            </strong>

            <Modal
                isOpen={modalIsOpen}
                onRequestClose={handleCloseModal}
                className="modal-Terminos"
                overlayClassName="overlay-Terminos"
                ariaHideApp={false}
            >
                <div className='modal-Terminos-contain'>
                    <img src={`${baseURL}/terminos.png`} alt="Terminos" />
                    <button onClick={handleCloseModal}>OK</button>
                </div>
            </Modal>
        </div>
    );
}
