import React, { useEffect, useState, useRef } from 'react';
import baseURL from '../url';
import './Rifas.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { Link as Anchor } from "react-router-dom";
import RifasLoading from '../RifasLoading/RifasLoading';
export default function Rifas() {
    const [rifas, setRifas] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        cargarRifas();


    }, []);
    const cargarRifas = () => {
        fetch(`${baseURL}/rifaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setRifas(data.rifas);
                setLoading(false);
            })
            .catch(error => console.error('Error al cargar rifas:', error));
    };
    return (
        <div className='Content'>
            {rifas?.length > 0 && (
                <div className='titleSection'>
                    <h2>Nuestros Sorteos</h2>
                    <FontAwesomeIcon icon={faAngleDoubleRight} className='iconTitle' />
                </div>

            )}
            {loading ? (
                <RifasLoading />
            ) : (
                <div className='DataGrap'>
                    {rifas?.map(item => (
                        <Anchor className='rifaCard' to={`/rifa/${item.idRifa}/${item?.nombre.replace(/\s+/g, '-')}`}>
                            <img src={item?.imagen1} alt="imagen rifa" />
                            <div className='cardRifaText'>
                                <h3>{item?.nombre}</h3>
                                <div className='deFlexPrix'>
                                    <h4>{item?.moneda} {item?.precio}</h4>

                                    {item?.estado === 'finalizada' ? (
                                        <strong>Rifa Finalizada</strong>
                                    ) : <strong>{item.finalizacion}</strong>}
                                </div>
                            </div>

                        </Anchor>
                    ))

                    }

                </div>
            )}
        </div>
    )
}
