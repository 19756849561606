import React from 'react'
import './RifasLoading.css'
export default function RifasLoading() {
    return (
        <div className='RifasLoading'>
            <div className='RifaLoading'>

            </div>
            <div className='RifaLoading'>

            </div>
            <div className='RifaLoading'>

            </div>
            <div className='RifaLoading'>

            </div>
            <div className='RifaLoading'>

            </div>
            <div className='RifaLoading'>

            </div>
        </div>
    )
}
