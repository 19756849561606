import React, { useState, useEffect } from 'react';
import './Grafico.css';
import { Chart } from 'primereact/chart';
import baseURL from '../../url';

export default function Grafico1() {
    const [rifas, setRifas] = useState([]);
    const [numeros, setNumeros] = useState([]);
    const [rifaSeleccionada, setRifaSeleccionada] = useState(null);
    const [chartData, setChartData] = useState({});
    const [totales, setTotales] = useState({
        reservados: 0,
        pendientes: 0,
        disponibles: 0,
        total: 0,
    });

    useEffect(() => {
        cargarRifas();
        cargarNumeros();
    }, []);

    useEffect(() => {
        if (rifas.length > 0) {
            // Seleccionar automáticamente la primera rifa si existe
            manejarCambioRifa(rifas[0].idRifa);
        } else {
            // Mostrar gráfico de ejemplo si no hay rifas
            mostrarGraficoEjemplo();
        }
    }, [rifas]);

    const cargarRifas = () => {
        fetch(`${baseURL}/rifaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => setRifas(data.rifas || []))
            .catch(error => console.error('Error al cargar rifas:', error));
    };

    const cargarNumeros = () => {
        fetch(`${baseURL}/numeroGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => setNumeros(data.numeros || []))
            .catch(error => console.error('Error al cargar números:', error));
    };

    const manejarCambioRifa = (idRifa) => {
        setRifaSeleccionada(idRifa);
        const numerosFiltrados = numeros.filter(numero => String(numero.idRifa) === String(idRifa));
        procesarDatos(numerosFiltrados);
    };

    const procesarDatos = (numerosFiltrados) => {
        let reservados = 0;
        let pendientes = 0;
        let disponibles = 0;

        numerosFiltrados.forEach(numero => {
            if (numero.estado === 'reservado') {
                reservados += 1;
            } else if (numero.estado === 'pendiente') {
                pendientes += 1;
            } else if (numero.estado === 'disponible') {
                disponibles += 1;
            }
        });

        const total = reservados + pendientes + disponibles;

        // Actualizar los totales
        setTotales({ reservados, pendientes, disponibles, total });

        // Generar datos del gráfico
        generarGrafico(reservados, pendientes, disponibles);
    };

    const generarGrafico = (reservados, pendientes, disponibles) => {
        const data = {
            labels: ['Reservados', 'Pendientes', 'Disponibles'],
            datasets: [
                {
                    label: 'Estados de los Números',
                    data: [reservados, pendientes, disponibles],
                    backgroundColor: ['#0c71cf', '#ffa726', '#009429'],
                    hoverBackgroundColor: ['#0858a6', '#ff8c00', '#009429'],
                },
            ],
        };
        setChartData(data);
    };

    const mostrarGraficoEjemplo = () => {
        const dataEjemplo = {
            labels: ['Reservados', 'Pendientes', 'Disponibles'],
            datasets: [
                {
                    label: 'Gráfico de Ejemplo',
                    data: [10, 5, 15],
                    backgroundColor: ['#0c71cf', '#ffa726', '#009429'],
                    hoverBackgroundColor: ['#0858a6', '#ff8c00', '#009429'],
                },
            ],
        };
        setChartData(dataEjemplo);
        setTotales({
            reservados: 10,
            pendientes: 5,
            disponibles: 15,
            total: 30,
        });
    };

    return (
        <div className="GraficoContent">
            <h3 className="titleGrafico">Estados de los Números por Rifa</h3>

            {/* Selección de rifa */}
            <div className="selector-rifas">
                <select
                    id="rifaSelect"
                    value={rifaSeleccionada || ''}
                    onChange={(e) => manejarCambioRifa(e.target.value)}
                >
                    <option value="" disabled>Selecciona una rifa</option>
                    {rifas.map(rifa => (
                        <option key={rifa.idRifa} value={rifa.idRifa}>
                            {rifa.nombre}
                        </option>
                    ))}
                </select>
            </div>

            {/* Totales */}
            <div className="totales">
                <span><strong>Reservados:</strong> {totales.reservados}</span>
                <span><strong>Pendientes:</strong> {totales.pendientes}</span>
                <span><strong>Disponibles:</strong> {totales.disponibles}</span>
                <span><strong>Total:</strong> {totales.total}</span>
            </div>

            {/* Gráfico */}
            <div className="grafico-container">
                <Chart type="pie" data={chartData} />
            </div>
        </div>
    );
}
