import React from 'react';
import './Layouts.css'

import Nabvar from '../Components/Navbar/Navbar'
import Footer from '../Components/Footer/Footer'
import Banners from '../Components/Banners/Banners'
import Rifas from '../Components/Rifas/Rifas';
import BtnWhatsapp from '../Components/BtnWhatsapp/BtnWhatsapp'
export default function IndexLayout() {




    return (
        <div className='section-bg-color'>
            <Nabvar />
            <div className='espaciobg'>

            </div>
            <div className='demo'>
                <Banners />
                <Rifas />
            </div>
            <Footer />
            <BtnWhatsapp />
        </div>
    );
}
