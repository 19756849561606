import React, { useState, useEffect } from 'react';
import './Grafico.css';
import { Chart } from 'primereact/chart';
import baseURL from '../../url';

export default function Grafico2() {
    const [chartData, setChartData] = useState({});
    const [chartOptions, setChartOptions] = useState({});
    const [numeros, setNumeros] = useState([]);
    const [rifas, setRifas] = useState([]);

    useEffect(() => {
        cargarRifas();
        cargarNumeros();
    }, []);

    // Cargar rifas
    const cargarRifas = () => {
        fetch(`${baseURL}/rifaGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setRifas(data.rifas || []);
            })
            .catch(error => console.error('Error al cargar rifas:', error));
    };

    // Cargar números
    const cargarNumeros = () => {
        fetch(`${baseURL}/numeroGet.php`, {
            method: 'GET',
        })
            .then(response => response.json())
            .then(data => {
                setNumeros(data.numeros || []);
            })
            .catch(error => console.error('Error al cargar números:', error));
    };

    // Procesar datos para el gráfico
    const procesarDatosGrafico = () => {
        if (rifas.length === 0) {
            mostrarGraficoEjemplo();
            return;
        }

        const numerosReservados = numeros.filter(numero => numero.estado === 'reservado');
        const numerosPorRifa = {};

        // Agrupar números reservados por idRifa
        numerosReservados.forEach(numero => {
            const { idRifa } = numero;
            if (!numerosPorRifa[idRifa]) {
                numerosPorRifa[idRifa] = { totalNumeros: 0, reservados: 1 }; // Inicia con reservados = 1
            } else {
                numerosPorRifa[idRifa].reservados += 1;
            }
        });

        // Calcular el total de números por rifa
        numeros.forEach(numero => {
            const { idRifa } = numero;
            if (!numerosPorRifa[idRifa]) {
                numerosPorRifa[idRifa] = { totalNumeros: 1, reservados: 0 };
            } else {
                numerosPorRifa[idRifa].totalNumeros += 1;
            }
        });

        // Ordenar rifas por cantidad de números reservados
        const rifasOrdenadas = Object.entries(numerosPorRifa)
            .sort(([, a], [, b]) => b.reservados - a.reservados)
            .slice(0, 6);

        const labels = rifasOrdenadas.map(([idRifa, { totalNumeros, reservados }]) => {
            const rifa = rifas.find(r => String(r.idRifa) === String(idRifa));
            const rifaNombre = rifa ? rifa.nombre.slice(0, 15) : `Rifa ${idRifa}`; // Limitar a 15 caracteres
            return `${rifaNombre}: Reservados: ${reservados}`;
        });

        const data = rifasOrdenadas.map(([, { reservados }]) => reservados);

        const backgroundColors = generateColorShades('#009429', labels.length);

        setChartData({
            labels: labels,
            datasets: [
                {
                    label: 'Números reservados por rifa',
                    data: data,
                    backgroundColor: backgroundColors,
                    borderColor: '#009429',
                    borderWidth: 1,
                },
            ],
        });

        setChartOptions({
            plugins: {
                legend: {
                    labels: {
                        color: '#333',
                    },
                },
            },
            scales: {
                y: {
                    beginAtZero: true,
                    grid: {
                        color: '#ccc',
                    },
                },
            },
        });
    };

    // Mostrar gráfico de ejemplo
    const mostrarGraficoEjemplo = () => {
        const backgroundColors = generateColorShades('#009429', 3);

        setChartData({
            labels: ['Ejemplo 1', 'Ejemplo 2', 'Ejemplo 3'],
            datasets: [
                {
                    label: 'Gráfico de Ejemplo',
                    data: [10, 15, 20],
                    backgroundColor: backgroundColors,
                    borderColor: '#009429',
                    borderWidth: 1,
                },
            ],
        });

        setChartOptions({
            plugins: {
                legend: {
                    labels: {
                        color: '#333',
                    },
                },
            },
            scales: {
                y: {
                    beginAtZero: true,
                    grid: {
                        color: '#ccc',
                    },
                },
            },
        });
    };

    // Generar tonos de color
    const generateColorShades = (baseColor, count) => {
        const r = parseInt(baseColor.slice(1, 3), 16);
        const g = parseInt(baseColor.slice(3, 5), 16);
        const b = parseInt(baseColor.slice(5, 7), 16);

        const shades = [];
        for (let i = 0; i < count; i++) {
            const alpha = 0.7 - i * 0.1;
            shades.push(`rgba(${r}, ${g}, ${b}, ${alpha})`);
        }
        return shades;
    };

    // Llamamos a procesarDatosGrafico solo cuando los datos estén cargados
    useEffect(() => {
        procesarDatosGrafico();
    }, [rifas, numeros]);

    return (
        <div className="GraficoContent">
            <h3 className="titleGrafico">Números reservados por rifa</h3>
            <div className="grafico-container-2">
                <Chart type="polarArea" data={chartData} options={chartOptions} />
            </div>
        </div>
    );
}
